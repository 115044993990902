import { Photo } from "@/models/Photo";

export class ThumbnailImageData {
  constructor(
    public image: HTMLImageElement,
    public id: number,
    public x: number,
    public y: number
  ) {}
}

export class ImageData {
  constructor(
    public images: Photo[],
    public thumbnailsData: Photo[],
    public currentImageIndex: number,
    public currentWindowImage: HTMLImageElement,
    public imageScale: number
  ) {}
}

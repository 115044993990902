const MIN_ZOOM = 1;
const MAX_ZOOM = 5;
const ZOOM_FACTOR = 1.3;
const BASIC_WIDTH = 530;
const BASIC_HEIGHT = 706;

enum ZoomMode {
  // eslint-disable-next-line no-unused-vars
  Normal,
  // eslint-disable-next-line no-unused-vars
  Move
}

export class ZoomManager {
  private _zoom = MIN_ZOOM;
  private _maxZoom = MAX_ZOOM;
  private readonly _minZoom = MIN_ZOOM;
  private readonly _factor = ZOOM_FACTOR;
  private _mode = ZoomMode.Normal;
  private _imageOffset = {
    x: 0,
    y: 0
  };
  private zoomMoveOffset = {
    x: 0,
    y: 0
  };
  private _imageSize = {
    width: 0,
    height: 0
  };
  private _imageScale = 1;

  public get zoomValue() {
    return this._zoom;
  }

  public get mode() {
    return this._mode;
  }

  public get imageOffset() {
    return this._imageOffset;
  }

  public get imageSize() {
    return this._imageSize;
  }

  public get cursorStyle() {
    return this.isZoomNormalMode() ? "auto" : "grab";
  }

  public set imageSize(imageSize: any) {
    this._imageSize.width = imageSize.width;
    this._imageSize.height = imageSize.height;
  }

  public set imageScale(scale: number) {
    this._imageScale = scale;
  }

  public adjustMaxZoom() {
    if (
      this._imageSize.width > BASIC_WIDTH ||
      this._imageSize.height > BASIC_HEIGHT
    ) {
      let avgWidthHeight = (this.imageSize.width + this.imageSize.height) / 2;
      let basicAvgWidthHeight = (BASIC_WIDTH + BASIC_HEIGHT) / 2;
      this._maxZoom =
        MAX_ZOOM *
        (1 + (avgWidthHeight - basicAvgWidthHeight) / avgWidthHeight);
    }
  }

  public changeZoom(event: any, mousePosition: any) {
    if (!event.evt.ctrlKey) {
      return;
    }

    event.evt.preventDefault();

    if (
      (this._zoom >= this._maxZoom && event.evt.deltaY < 0) ||
      (this._zoom <= this._minZoom && event.evt.deltaY > 0)
    ) {
      return;
    }

    let [x, y] = mousePosition;
    let [scaledX, scaledY] = [
      this.nominalPositionX(x, false),
      this.nominalPositionY(y, false)
    ];
    scaledX -= this._imageOffset.x;
    scaledY -= this._imageOffset.y;
    let [originalWidth, originalHeight] = [
      this._imageSize.width / this._zoom,
      this._imageSize.height / this._zoom
    ];
    this._zoom =
      event.evt.deltaY < 0
        ? this._zoom * this._factor
        : this._zoom / this._factor;
    this._zoom = Math.max(this._minZoom, this._zoom);
    this._zoom = Math.min(this._maxZoom, this._zoom);
    let [w, h] = [
      this._imageSize.width / this._zoom,
      this._imageSize.height / this._zoom
    ];
    this._imageOffset = {
      x: this._imageOffset.x + scaledX - (scaledX * w) / originalWidth,
      y: this._imageOffset.y + scaledY - (scaledY * h) / originalHeight
    };
  }

  public zoomedPositionX(x: number) {
    x *= this._imageScale;
    return (x - this._imageOffset.x) * this._zoom;
  }

  public zoomedPositionY(y: number) {
    y *= this._imageScale;
    return (y - this._imageOffset.y) * this._zoom;
  }

  public nominalPositionX(x: number, applyImageScale = true) {
    let value =
      x / this._zoom + this._imageOffset.x - this.zoomMoveOffset.x / this._zoom;
    if (applyImageScale) {
      value /= this._imageScale;
    }
    return value;
  }

  public nominalPositionY(y: number, applyImageScale = true) {
    let value =
      y / this._zoom + this._imageOffset.y - this.zoomMoveOffset.y / this._zoom;
    if (applyImageScale) {
      value /= this._imageScale;
    }
    return value;
  }

  public nominalPositionNoDragX(x: number, applyImageScale = true) {
    let value = x / this._zoom + this._imageOffset.x;
    if (applyImageScale) {
      value /= this._imageScale;
    }
    return value;
  }

  public nominalPositionNoDragY(y: number, applyImageScale = true) {
    let value = y / this._zoom + this._imageOffset.y;
    if (applyImageScale) {
      value /= this._imageScale;
    }
    return value;
  }

  public isZoomNormalMode() {
    return this._mode === ZoomMode.Normal;
  }

  public resetZoom() {
    this._zoom = MIN_ZOOM;
    this._imageOffset = { x: 0, y: 0 };
    this.zoomMoveOffset = { x: 0, y: 0 };
    this._mode = ZoomMode.Normal;
  }

  public toggleMode() {
    if (this.isZoomNormalMode()) {
      this._mode = ZoomMode.Move;
    } else {
      this._mode = ZoomMode.Normal;
    }
  }

  public dragImageBoundaries(pos: any) {
    const newWidth = this._imageSize.width / this._zoom;
    const newHeight = this._imageSize.height / this._zoom;
    pos.x = Math.max(
      pos.x,
      -(this._imageSize.width - this._imageOffset.x - newWidth) * this._zoom
    );
    pos.x = Math.min(pos.x, this._imageOffset.x * this._zoom);
    pos.y = Math.max(
      pos.y,
      -(this._imageSize.height - this._imageOffset.y - newHeight) * this._zoom
    );
    pos.y = Math.min(pos.y, this._imageOffset.y * this._zoom);
    this.zoomMoveOffset = {
      x: pos.x,
      y: pos.y
    };
    return {
      x: pos.x,
      y: pos.y
    };
  }
}

import { PhotoEntity } from "@/entities/Photo.entity";
import { Photo } from "@/models/Photo";

class PhotoMapper {
  mapPhoto(photo: PhotoEntity): Photo {
    return new Photo(photo.id, photo.upload);
  }

  mapPhotos(photoEntities: PhotoEntity[]): Photo[] {
    let photos: Photo[] = [];

    photoEntities.forEach(photo => {
      photos.push(this.mapPhoto(photo));
    });

    return photos;
  }
}

// Export a singleton instance in the global namespace
export const photoMapper = new PhotoMapper();

import { QuestionChoiceEntity } from "@/entities/QuestionChoice.entity";
import { QuestionEntity } from "@/entities/Question.entity";

export class ClassificationTask {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public photo_collection: number,
    public question: QuestionEntity,
    public choices: QuestionChoiceEntity[]
  ) {}
}

import { VisualTaskWithCategoriesEntity } from "@/entities/VisualTaskWithCategories.entity";
import { VisualTask } from "@/models/VisualTask";
import { categoryMapper } from "@/mappers/category.mapper";
import { ClassificationTask } from "@/models/ClassificationTask";
import { ClassificationTaskEntity } from "@/entities/ClassificationTaskEntity";
import { ClassificationTaskWithQuestionEntity } from "@/entities/ClassificationTaskWithQuestion.entity";
import { questionMapper } from "@/mappers/question.mapper";

class TaskMapper {
  mapVisualTaskWithCategories(
    task: VisualTaskWithCategoriesEntity
  ): VisualTask {
    return new VisualTask(
      task.id,
      task.name,
      task.description,
      task.photo_collection,
      categoryMapper.mapCategories(task.categories)
    );
  }

  mapClassificationTask(
    task: ClassificationTaskWithQuestionEntity
  ): ClassificationTask {
    return new ClassificationTask(
      task.id,
      task.name,
      task.description,
      task.photo_collection,
      task.question,
      task.question_choices
    );
  }
}

// Export a singleton instance in the global namespace
export const taskMapper = new TaskMapper();

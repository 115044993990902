import { Category } from "@/models/Category";

export class VisualTask {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public photo_collection: number,
    public categories: Category[]
  ) {}
}

import { CategoryEntity } from "@/entities/Category.entity";
import { Category } from "@/models/Category";
import { AnnotationType } from "@/models/enums/AnnotationType";

class CategoryMapper {
  mapCategory(category: CategoryEntity): Category {
    return new Category(
      category.id,
      category.name,
      category.description,
      category.visual_selection_style == 1
        ? AnnotationType.Polygons
        : AnnotationType.Rectangles
    );
  }

  mapCategories(categoryEntities: CategoryEntity[]): Category[] {
    let categories: Category[] = [];

    categoryEntities.forEach(category => {
      categories.push(this.mapCategory(category));
    });

    return categories;
  }
}

// Export a singleton instance in the global namespace
export const categoryMapper = new CategoryMapper();
